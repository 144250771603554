import { FEATURE_FLAGS } from '../feature-flags';
import { appRoutes } from './routing';

export interface ISideMenuItemType {
  id: string;
  label: string;
  route?: string;
  icon?: any;
  children?: ISideMenuItemType[];
  accessKey?: string;
  hidden?: boolean;
}

const adminSideMenuList: ISideMenuItemType[] = [
  // { id: 'PLANS', label: 'Plan', route: '/', icon: null },
  {
    id: 'TENANT_MANAGEMENT',
    label: 'Tenant Management',
    icon: 'TENANT_MANAGEMENT',
    accessKey: 'ROOT',

    children: [
      { id: 'CO:TENANT-PROFILE', label: 'Tenant Profile', route: '/admin/tenant_Profile' },
      { id: 'CO:MANAGE-USER', label: 'Manage User', route: '/admin/manage_User', icon: null }
    ]
  },
  {
    id: 'TENANT_MANAGEMENT',
    label: 'Tenant Management',
    icon: 'TENANT_MANAGEMENT',
    accessKey: 'ADMIN',

    children: [
      { id: 'CO:TENANT-PROFILE', label: 'Tenant Profile', route: '/admin/tenant_Profile' },
      { id: 'CO:MANAGE-USER', label: 'Manage User', route: '/admin/manage_User', icon: null }
    ]
  },
  {
    id: 'ACCOUNT_MANAGEMENT',
    label: 'Account Management',
    // route: '/admin/account-management',
    icon: 'PRODUCT',
    accessKey: 'product',

    children: [
      { id: 'PLANS_AND_PRICING', label: 'Plans and Pricing', route: '/admin/account-management' },
      { id: 'SUBSCRIPTION_MANAGEMENT', label: 'Subscription Management', route: '/admin/subscription-management' },
      { id: 'BILLING_AND_PAYMENTS', label: 'Billing and Payments', route: '/admin/billing-payments' },
      { id: 'USAGE_AND_METERING', label: 'Usage and Metering', route: '/admin/usage-metering' }
    ]
  }

  // { id: 'FINANCE', label: 'Finance', route: '/', icon: null }
];

const loyaltySideMenuList: ISideMenuItemType[] = [
  // { id: 'PLANS', label: 'Plan', route: '/', icon: null },
  {
    id: 'LOYALTY_ENGINE',
    label: 'Elysian - Loyalty',
    accessKey: 'elysian',
    icon: 'LOYALTY_ENGINE',

    children: [
      { id: 'EL:DASHBOARD-ACCESS', label: 'Dashboard', route: '/loyalty/dashboard' },
      {
        id: 'EL:CONFIGURATIONS-ACCESS',
        label: 'Configuration',

        children: [
          { id: 'LOYALTY_ENGINE_CONFIG_REWARDS', label: 'Rewards Type', route: '/loyalty/config/rewards' },
          {
            id: 'LOYALTY_ENGINE_CONFIG_POINTS',
            label: 'Point Conversion Rules',
            route: '/loyalty/config/point-conversion'
          },
          { id: 'LOYALTY_ENGINE_CONFIG_RULES', label: 'Earn Rules', route: '/loyalty/config/earn-rule' },
          { id: 'LOYALTY_ENGINE_CONFIG_BURN_RULES', label: 'Burn Rules', route: '/loyalty/config/burn-rule' },
          { id: 'LOYALTY_ENGINE_CONFIG_TIER', label: 'Tier Management', route: '/loyalty/config/tiers' },
          { id: 'LOYALTY_ENGINE_CHANNEL', label: 'Channel ', route: '/loyalty/config/channel-list' } //NOT IN KEYCLOCK
        ]
      },
      {
        id: 'EL:ANALYTICS-ACCESS',
        label: 'Analytics',
        children: [{ id: 'LOYALTY_ENGINE_ANALYTICS_RFM', label: 'Customer Profiling', route: '/loyalty/analytics/rmf' }]
      },

      { id: 'EL:REPORT-ACCESS', label: 'Report', route: '/loyalty/reports' },
      {
        id: 'EL:COMMERCIAL-BALANCE-REPORT',
        label: 'Commercial Report',
        route: '/loyalty/commercial-report',
        hidden: !FEATURE_FLAGS?.COMMERCIAL_BALANCE_REPORT
      }
    ]
  }
];

const sideMenu = {
  sideMenuList: [
    {
      id: 'HOME',
      label: 'Home',
      icon: 'HOME',
      accessKey: 'home',
      route: '/'
    },
    ...adminSideMenuList,
    {
      id: 'EO:DATA-MANAGEMENT',
      label: 'Data Management',
      icon: 'NEBULA_APP',
      accessKey: 'data-management',
      children: [
        { id: 'EO:CUSTOMERS-ACCESS', label: 'Customers', route: '/data-management/customers' },
        { id: 'EO:PRODUCTS-ACCESS', label: 'Products', route: '/data-management/products' }
      ]
    },

    {
      id: 'NEBULA_APP',
      label: 'Nebula - OMS',
      accessKey: 'nebula',
      icon: 'NEBULA_APP',
      children: [
        {
          id: 'NE:ORDER-DASHBOARD-ACCESS',
          label: 'Dashboard',
          route: '/nebula/order/dashboard',
          icon: null,
          children: []
        },
        {
          id: 'NE:ORDERS-ACCESS',
          label: 'Orders',
          icon: null,
          children: [
            { route: '/order', id: 'ORDER', label: 'Orders' },
            { route: '/release-order', id: 'RELEASE_ORDER', label: 'Release Orders' },
            { route: '/transfer-order', id: 'TRANSFER_ORDER', label: 'Transfer Orders' }
          ]
        },
        {
          id: 'NE:INVENTORY-ACCESS',
          label: 'Inventory',
          route: '/inventory',
          icon: null,
          children: []
        },
        {
          id: 'NE:PRODUCTS',
          label: 'Products',
          route: '/order-products',
          icon: null,
          children: []
        },
        {
          id: 'NE:NODES-ACCESS',
          label: 'Nodes',
          route: '/nodes',
          icon: null,
          children: []
        },
        {
          id: 'NE:OPERATORS-ACCESS',
          label: 'Operators',
          route: '/operators',
          icon: null,
          children: []
        },
        {
          id: 'NE:LOGISTICS-ACCESS',
          label: 'Logistics',
          // hidden: !FEATURE_FLAGS.ENABLE_LOGISTICS,
          icon: null,
          children: [
            { route: '/logistics/dnn', id: 'DNN', label: 'DNN' },
            { route: '/logistics/snn', id: 'SNN', label: 'SNN' }
            // { route: '/logistics/shipping-profile', id: 'SHIPPING_PROFILE', label: 'Shipping Profile' },
            // { route: '/logistics/service-network', id: 'SERVICE_NETWORK', label: 'Service Network' }
          ]
        },
        { route: '/order-create', id: 'NE:ORDER-CREATION', label: 'Create Order Testing' },
        {
          id: 'NE:PROMISE',
          label: 'Promise',
          icon: null,
          children: [
            { route: '/promise/dashboard', id: 'DASHBOARD', label: 'Dashboard' },
            { route: '/Promise', id: 'Promise', label: 'Promise' }
            // { route: '/Report', id: 'Report', label: 'Report' }
          ]
        }
      ]
    },
    {
      id: 'PROMOTION_APP',
      icon: 'PROMOTION_APP',
      label: 'Opus - Promotion',
      accessKey: 'opus',
      children: [
        { id: 'OP:DASHBOARD-ACCESS', label: 'Dashboard', route: '/promotion-engine/promotions/dashboard' },
        {
          id: 'OP:PROMO-PRIORITY-ACCESS',
          label: 'Promotion Settings',
          route: '/promotion-engine/promotions/priority',
          icon: null
        },
        // {
        //   id: 'PROMOTION_LIST',
        //   label: 'Promotions',
        //   route: '/promotion-engine/promotions',
        //   icon: null,
        // },
        {
          id: 'OP:PROMOTIONS-ACCESS',
          label: 'Promotions',
          icon: null,
          children: [
            {
              route: '/promotion-engine/promotions/sub_total_discount',
              id: 'SUB_TOTAL_DISCOUNT',
              label: 'Sub Total Discount'
            },
            { route: '/promotion-engine/promotions/volume_discount', id: 'VOLUME_DISCOUNT', label: 'Volume Discount' },
            { route: '/promotion-engine/promotions/bundle_discount', id: 'BUNDLE_DISCOUNT', label: 'Bundle Discount' },
            {
              route: '/promotion-engine/promotions/shipping_discount',
              id: 'SHIPPING_DISCOUNT',
              label: 'Shipping Discount'
            },
            { route: '/promotion-engine/promotions/bxgy', id: 'BXGY', label: 'BXGY' },
            { route: '/promotion-engine/promotions/loyalty_reward', id: 'LOYALTY', label: 'Loyalty Reward' },
            {
              route: '/promotions/new-sub-total-discount',
              id: 'NEW_SUB_TOTAL_DISCOUNT',
              label: 'New Sub Total Discount',
              hidden: !FEATURE_FLAGS?.NEW_SUB_TOTAL_DISCOUNT
            },
            {
              route: '/promotions/new-bundle-discount',
              id: 'NEW_BUNDLE_DISCOUNT',
              label: 'New Bundle Discount',
              hidden: !FEATURE_FLAGS?.NEW_SUB_TOTAL_DISCOUNT
            },
            {
              route: '/promotions/new-shipping-discount',
              id: 'NEW_SHIPPING_DISCOUNT',
              label: 'New Shipping Discount',
              hidden: !FEATURE_FLAGS?.NEW_SUB_TOTAL_DISCOUNT
            }
          ]
        },
        {
          id: 'OP:PRICELIST-ACCESS',
          label: 'Price List Discount',
          route: '/promotion-engine/promotions/price-list',
          icon: null
          // hidden: !FEATURE_FLAGS?.ENABLE_PRICELIST
        },
        {
          id: 'OP:CATALOG-ACCESS',
          label: 'Catalog',
          route: '/promotion-engine/promotions/catalog',
          icon: null
          // hidden: !FEATURE_FLAGS?.ENABLE_PRICELIST
        },
        {
          id: 'OP:REPORT-ACCESS',
          label: 'Report',
          route: '/promotion-engine/promotions/report',
          icon: null
        },
        {
          id: 'TEST_ENCRYPTION',
          label: 'Encryption Test Page',
          route: '/promotion-engine/promotions/test-encryption',
          icon: null,
          hidden: !FEATURE_FLAGS?.TEST_ENCRYPTION
        }
      ]
    },
    ...loyaltySideMenuList

    // {
    //   id: 'ORDER_CREATION',
    //   label: 'Order Creation',
    //   route: '/order-create',
    //   icon: null,
    //   children: [1,00,000.00]
    // }
  ]
};
interface routeToMenuKeyMapType {
  [key: string]: any;
}

const routeToMenuKeyMap: routeToMenuKeyMapType = {};
type MenuItem = {
  id: string;
  label: string;
  icon?: string | null;
  accessKey?: string;
  route?: string;
  children?: MenuItem[];
};

function generateRouteToMenuKeyMap(menu: MenuItem[], parentKey = null) {
  menu.forEach((item: MenuItem) => {
    const key = item.id;
    if (item.route) {
      routeToMenuKeyMap[item.route as string] = { menuKey: key };
    }
    if (item.children && item.children.length > 0) {
      generateRouteToMenuKeyMap(item.children);
    }
  });
}
generateRouteToMenuKeyMap(sideMenu.sideMenuList);

function findUrlForSideBarSelection(path: string) {
  while (path !== '') {
    if (routeToMenuKeyMap[path as keyof typeof routeToMenuKeyMap]) {
      return path;
    }
    path = path.substring(0, path.lastIndexOf('/'));
  }
  return '';
}
export { sideMenu, routeToMenuKeyMap, findUrlForSideBarSelection };

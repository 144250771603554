import { Card, Col, Form, RadioChangeEvent, Row, Steps, Typography } from 'antd';
import PrimaryLayout from '../../../../layouts/primary-layout';
import TenantInfo from '../../../../components/TenantIdInfo';
import React from 'react';
import PromoForm from './promo-form';
import RewardForm from './reward-form';
import { useSearchParams } from 'react-router-dom';
import { domHelpers } from '../../../../helpers/dom.helpers';

interface IPromotionCreate {}

const PromotionCreate: React.FunctionComponent<IPromotionCreate> = props => {
  const [promotionForm] = Form.useForm();
  const [rewardForm] = Form.useForm();
  const [searchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [bdyData, setBdyData] = React.useState({} as any);
  const [validTimeFrame, setValidTimeFrame] = React.useState(1);

  React.useEffect(() => {
    loadIntialForm();
  }, []);

  const loadIntialForm = () => {
    const promoType = searchParams.get('promotion_type');
    promotionForm.setFieldsValue({
      trigger: searchParams.get('promo_header_type'),
      promotionType: searchParams.get('promotion_type'),
      max_discount_item_count: 1,
      applicability: 'CART',
      onlyForNewUser: false,
      isAvailablePos: true,
      isAvailableOnline: true,
      priority: 1,
      chooseEcomType: 'All',
      chooseStoreType: 'All',
      productSelection_RC: 'ALL',
      status: 'OPEN',
      limit_per_user: 1,
      use_max_limit: true,
      seller_Selection: 'ALL',
      thresholdSelection: 'NO_THRESHOLD',
      rewards_Data: [
        {
          product_reward: {
            product_info: [{}]
          }
        }
      ],
      for_generate: 'REGISTERED_USER',
      customerRestriction: 'ALL',
      filters: [{}]
    });

    if (promoType === 'BUNDLE_DISC') {
      promotionForm.setFieldsValue({
        appliesTo: 'LINE_ITEMS',
        rewardType: 'BUNDLE_DISCOUNT',
        discount_type: 'PERCENTAGE'
      });
    } else if (promoType === 'SHIPPING_DISC') {
      promotionForm.setFieldsValue({
        appliesTo: 'SHIPPING',
        rewardType: 'DISCOUNT'
        // threshold_selection: 'NO_THRESHOLD',
        // discount_value: 1,
        // max_discount: 1
        // shipping_discount: [{}]
      });
      rewardForm.setFieldsValue({
        threshold_selection: 'NO_THRESHOLD',
        discount_value: 1,
        max_discount: 1
        // shipping_discount: [{}]
      });
    } else if (promoType === 'VOLUME_DISC') {
      promotionForm.setFieldsValue({
        appliesTo: 'LINE_ITEMS',
        rewardType: 'VOLUME_DISCOUNT',
        rewards_Data: [
          {
            threshold_selection: 'QUANTITY',
            product_reward: {
              product_info: [{}]
            }
          }
        ],
        discount_type: 'PERCENTAGE',
        apply_discount_on: 'PRODUCTS'
      });
    } else if (promoType === 'BUNDLE_DISC') {
      promotionForm.setFieldsValue({
        appliesTo: 'LINE_ITEMS',
        rewardType: 'BUNDLE_DISCOUNT'
      });
    } else if (promoType === 'BXGY') {
      promotionForm.setFieldsValue({
        appliesTo: 'LINE_ITEMS',
        rewardType: 'PRODUCT',
        BXGY_buy: [{}],
        BXGY_get: [{}],
        applies_to_price: 'NONE',
        discount_type: 'PERCENTAGE',
        apply_discount_on: 'PRODUCTS'
      });
    } else if (promoType === 'LOYT_REWARD') {
      promotionForm.setFieldsValue({
        appliesTo: 'LOYT_POINTS',
        rewardType: 'POINTS'
      });
    } else if (promoType === 'SUB_TTL_DIS') {
      promotionForm.setFieldsValue({
        appliesTo: 'LINE_ITEMS',
        rewardType: 'DISCOUNT',
        discount_type: 'PERCENTAGE',
        apply_discount_on: 'PRODUCTS',
        threshold_selection: 'NO_THRESHOLD',
        subtotal_treshold: [{ max_limit_set: false }]
      });
    }
  };

  const handleClickNext = async (currentFormValues: any, bodyData: any) => {
    domHelpers.scrollMainContentToTop();
    setBdyData(bodyData);
    setCurrentStep(prev => prev + 1);
  };

  const handleOnGoBack = (step: any, combinedBodyData: any) => {
    setCurrentStep(step);
    setBdyData(combinedBodyData);
  };

  const handleValidTimeFrame = (e: RadioChangeEvent) => {
    setValidTimeFrame(e.target.value);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Promotion
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <div className="w-1/2 mt-6">
            <Steps
              responsive
              current={currentStep}
              //   onChange={handleOnClickStep}
              items={[
                {
                  title: 'Promotion Creation'
                },
                {
                  title: 'Rewards'
                }
              ]}
            />
          </div>
          <div className="pt-6">
            {currentStep === 0 && (
              <PromoForm
                handleClickNext={handleClickNext}
                promotionForm={promotionForm}
                validTimeFrame={validTimeFrame}
                handleOnRadioVale={() => {}}
                handleValidTimeFrame={handleValidTimeFrame}
                bdyData={bdyData}
              />
            )}
            {currentStep === 1 && (
              <RewardForm
                rewardForm={rewardForm}
                handleOnGoBack={handleOnGoBack}
                promotionForm={promotionForm.getFieldsValue()}
                bdyData={bdyData}
                handleOnGoBackReward={() => {}}
              />
            )}
          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PromotionCreate;
